@import '../../../themes/default/theme';

.container {
  position: fixed;
  display: flex;
  inset: 0;
  z-index: 1300;
  justify-content: center;
  align-items: center;
}

.loading {
  $colors: $color-secondary-300, $color-secondary-400, $color-primary-400, $color-primary-500, $color-primary-600;
  display: flex;

  .dot {
    position: relative;
    width: 2em;
    height: 2em;
    margin: 0.8em;
    border-radius: 50%;

    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      background: inherit;
      border-radius: inherit;
      animation: wave 1.2s ease-out infinite;
    }

    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        background: nth($colors, $i);

        &::before {
          animation-delay: $i * 0.15s;
        }
      }
    }
  }
}
