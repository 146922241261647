html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

strong,
b {
  font-weight: 700;
}

body {
  margin: 0;
  font-family: 'OpenSans', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.43;
  background-color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a,
a:hover,
a:focus,
a:active,
a:visited {
  text-decoration: none;
  outline: none;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-x-auto::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.resizer {
  margin-left: 4px;
  height: 80%;
  width: 1px;
  padding: 0 4px;
  border-right: 1px solid #b3b8c0;
  cursor: col-resize;
  user-select: none;
  touch-action: none;
  flex-shrink: 0;
}

.resizer.isResizing {
  border-right: 1px solid #004c91;
  opacity: 1;
  width: 1px;
}

@media (hover: hover) {
  .resizer {
    opacity: 0.5;
  }

  *:hover > .resizer {
    opacity: 1;
  }
}
