@font-face {
  font-family: 'OpenSans';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url('./fonts/OpenSansRegular.eot'), url('./fonts/OpenSansRegular.woff2') format('woff2'),
    url('./fonts/OpenSansRegular.woff') format('woff'), url('./fonts/OpenSansRegular.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSans';
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src: url('./fonts/OpenSansBold.eot'), url('./fonts/OpenSansBold.woff2') format('woff2'),
    url('./fonts/OpenSansBold.woff') format('woff'), url('./fonts/OpenSansBold.ttf') format('truetype');
}
@font-face {
  font-family: 'OpenSans';
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url('./fonts/OpenSansSemiBold.eot'), url('./fonts/OpenSansSemiBold.woff2') format('woff2'),
    url('./fonts/OpenSansSemiBold.woff') format('woff'), url('./fonts/OpenSansSemiBold.ttf') format('truetype');
}
